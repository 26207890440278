import { StaticImage } from "gatsby-plugin-image";
import * as React from "react"

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Sidebar from "../components/Sidebar";

const VancouverHistory = ({pageContext}) => {

    return <Layout
        pageContext={pageContext}
    >
        <Seo title="The Lesser-Told History of Vancouver" />
        <div className="sidebar-container">
            <Sidebar />
            <div className="sidebar-content text-content">
                <p className="featured">The VanWalks routes that you are enjoying take place on the traditional, ancestral and unceded territories of the Sḵwx̱wú7mesh (Squamish), Stó:lō and Səl̓ílwətaʔ/Selilwitulh (Tsleil-Waututh) and xʷməθkʷəy̓əm (Musqueam) Nations.</p>
                <p>These types of land acknowledgements are becoming very common in Vancouver. You may have seen them on websites or at the entrances of art galleries or museums. But they often are displayed without much explanation. If you are new to Vancouver or just visiting, it can be hard to gain an understanding of the current situation from these single sentences. This page will give you a quick overview of the history of Vancouver, focusing on Indigenous land rights. There will also be information on the current Land Back and renaming British Columbia movements.</p>
                <h1>The Lesser-Told History of Vancouver</h1>   
                <p>Canada is a young nation, on a global scale. Born July 1, 1867, Canada has only existed for just over 150 years. Even then, the first iteration of Canada only stretched as far west as Ontario. On the West Coast, Gastown was born the same year when Gassy Jack built a tavern just outside a fledgling sawmill. A short time later, under the umbrella of the British Empire, the Colony of British Columbia welcomed it’s newest town “Granville”. Granville was named after Lord Granville, a man who had never even visited here, but was the Colonial Secretary of the United Kingdom at the time.</p>
                <p>The history of settlement in this area, however, goes back much longer than that. The Coast Salish people were the densest and most linguistically diverse population in what is now Canada. They had been living in the Pacific Northwest for over seven thousand years and estimates of their population range from 200,000 to over a million. They didn’t have specific boundaries for their territory, and lived interconnectedly in the area that is now Vancouver. Some of the nations who lived in the area where VanWalks routes takes place, are the xʷməθkwəy̓əm (Musqueam), Skwxwú7mesh (Squamish) and Səl̓ílwətaʔ/Selilwitulh (Tsleil-Waututh) Nations.</p>
                <StaticImage
                    className="content-image"
                    src="../images/coast-salish-map.jpg"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="Map of Coast Salish Territories"
                    placeholder={"none"}
                    objectFit="contain"
                />
                <p>In what is now Stanley Park, there was a village called X̱wáýx̱way (Whay Whay), home to people of all three nations. Evidence of their leftover clam shells, called Midden, suggest the village had been there for over 3000 years. A huge long house was home to an estimated 100 people from 11 different families.</p>
                <p>Before the colonizers even arrived in the area, an even greater threat had already hit the Indigenous population, Smallpox. Smallpox had been living among the human population in Asia and Europe as far back as 10,000 BC. It was a very deadly disease and was one of the leading causes of death in human history. When the first Europeans arrived in Central America, the locals had never been exposed to a disease of this nature, and it ripped through the population. The disease slowly made its way up to the Pacific Northwest, and by the time the settlers came here, the Indigenous population was reduced by 66%, with losses of up to 90% in some areas.</p>
                <h2>The Indian Act and Residential Schools</h2>
                <p>The history of kicking Indigenous people out of their homes goes back as far as 1859, when the area that is now Stanley Park was declared a military reserve. And in 1876, the Indian Act, one of the worst pieces of legislation in Canadian history, expedited the process. The Indian Act forced Indigenous people to move to reserves and imposed a Euro-centric government system on them that ignored their pre-existing governance system, hereditary chiefs, and thousands of years of tradition. A residential school system also took children from their homes. They were terrible places, designed to “kill the Indian” within the children and assimilate them into the new culture. This is not ancient history, the last residential school closed in 1996, and new revelations about the horrors that took place are coming to light to this day.</p>
                <p>The goal of the Indian Act was cultural genocide, and it’s still a major piece of legislation used by the Government of Canada. There is much debate about abolishing the Act, but that is also a difficult proposition. The Act, as terrible as it has been, also forces the current government to acknowledge the mistakes it has made and to acknowledge the rights of indigenous people who fall under the purview of this law.</p>
                <ul>
                    <li>
                        <a
                            href="https://indigenousfoundations.arts.ubc.ca/the_indian_act/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            The Indian Act - UBC Indigenous Foundations
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.thecanadianencyclopedia.ca/en/article/indian-act"
                            target="_blank"
                            rel="noreferrer"
                        >
                            The Indian Act - The Canadian Encyclopedia
                        </a>
                    </li>
                </ul>
                <h2>Unceded Land</h2>
                <p>Unceded land is a topic that has been garnering a lot of attention in the 21st century. In most of Canada, Indigenous land was purchased by the “Crown of Canada” through a series of treaties. Those treaties generally stopped at the Rocky Mountains and the fairness of the treaties is also highly contested. The province of British Columbia had a “no-treaty” policy when it came to displacing people from their homes. When Canada acquired BC as a province, they let the no-treaty policy slide, and nothing was ever negotiated.</p>
                <p>As you can see from this map, BC has made a few new treaties. Some of them are very new and only relate to specific geographic areas.</p>
                <StaticImage
                    className="content-image"
                    src="../images/coast-salish-treaties.png"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="Map of Treaties in BC"
                    placeholder={"none"}
                    objectFit="contain"
                />
                <ul>
                    <li>
                        <a
                            href="https://native-land.ca/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Interactive Map of Indigenous Territories and Treaties
                        </a>
                    </li>
                </ul>
                <p>According to definitions laid out by the UN, the indigenous nations within Canada are recognized nations and should be on equal footing legally with regards to their land claims. In practical terms, the governments of Canada and BC are still treating them poorly. Land defenders have been fighting to stop pipelines from going through their land and trying to prevent the last remaining old growth forests from being destroyed by logging.</p>
                <p>Recently an indigenous chief was arrested while performing a ceremony on their ancestral land. Can you imagine if that happened to the leader of a nation like France?</p>
                <h2>Where do we go from here?</h2>
                <p>There are two movements that have been growing in BC, and in particular in Vancouver. The Land Back movement is pushing to return the stolen land and bring it back under the control of its original caretakers. The second movement is trying to rename &quot;British Columbia&quot;, a double barrel blast of colonialism and racism, to something more inclusive for everyone who lives here.</p>
                <ul>
                    <li>
                        <a
                            href="https://breachmedia.ca/land-back/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Breach Media Land Back Video
                        </a>
                    </li>
                    <li>
                        <a
                            href="http://4rsyouth.ca/land-back-what-do-we-mean/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            4Rs Youth - What does Land Back Mean
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://ipolitics.ca/2021/08/04/making-the-case-for-renaming-british-columbia/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Making the case for renaming British Columbia -  Andrew Fleming
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.canadiangeographic.ca/article/renaming-places-how-canada-reexamining-map"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Renaming places: how Canada is reexamining the map - Canadian Geography
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </Layout>
};

export default VancouverHistory
